import React from 'react';
import Feedbacks from './Feedbacks';

const LandingPage = () => {
  return (
    <div>
      <Feedbacks />
    </div>
  )
};

export default LandingPage;