import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { store, history } from './store'
import { Switch } from 'react-router';
import LandingPage from './containers/LandingPage';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history} >
          <div>
            <Switch>
              <Route exact path="/" component={LandingPage} />
            </Switch>
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;
