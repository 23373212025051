import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyB62OwGY7o1UKlv0Isa2FvxhkeG6vt5lCc',
  authDomain: 'mama-mobile-238622.firebaseapp.com',
  databaseURL: 'https://mama-mobile-238622.firebaseio.com',
  projectId: 'mama-mobile-238622',
  storageBucket: 'mama-mobile-238622.appspot.com',
  messagingSenderId: '511658284172',
  appId: '1:511658284172:web:b909cd0ad8a42b8c',
};

firebase.initializeApp(config);
firebase.firestore().settings({});

export default firebase;
