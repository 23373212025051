import React from 'react';
import { Header, Table, Rating } from 'semantic-ui-react';

class Feedbacks extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      feedbacks: [],
      offset: 0,
      limit: 20,
    }
  }

  componentDidMount = () => {
    const options = {
      method: 'POST',
      body: JSON.stringify({
        offset: this.state.offset,
        limit: this.state.limit,
      }), 
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    }

    fetch('https://us-central1-mama-mobile-238622.cloudfunctions.net/getAllFeedbacks', options)
    .then(res => res.json())
    .then(data => {
      this.setState({
        feedbacks: data,
      })
    })
    .catch(err => console.log(err));
  }

  render = () => {
    return (
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine>Name</Table.HeaderCell>
            <Table.HeaderCell>Phone Number</Table.HeaderCell>
            {/* <Table.HeaderCell>Appointment Date</Table.HeaderCell> */}
            {/* <Table.HeaderCell>Massage Type</Table.HeaderCell> */}
            <Table.HeaderCell>RMT</Table.HeaderCell>
            <Table.HeaderCell>Rating</Table.HeaderCell>
            <Table.HeaderCell>Comments</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
    
        <Table.Body>
          {this.state.feedbacks.map(feedback => {
            const tel = `(${feedback.user_id.substring(0, 3)}) ${feedback.user_id.substring(3, 6)}-${feedback.user_id.substring(6)}`;
            return (
              <Table.Row key={feedback.feedback_id}>
                <Table.Cell>{`${feedback.firstName} ${feedback.lastName}`}</Table.Cell>
                <Table.Cell>{tel}</Table.Cell>
                {/* <Table.Cell>{feedback.appt_date ? feedback.appt_date.substring(0, 10) : null}</Table.Cell> */}
                {/* <Table.Cell>{feedback.service}</Table.Cell> */}
                <Table.Cell>{feedback.rmt}</Table.Cell>
                <Table.Cell>
                  <Rating icon='star' defaultRating={feedback.rating} maxRating={5} />
                </Table.Cell>
                <Table.Cell>{feedback.feedback}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    )
  }
} 

export default Feedbacks;
